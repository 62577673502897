@value colors: "../../../../components/library/style/colors.css";
@value color-purple, color-success from colors;

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  margin-right: 16px;
  font-weight: 500;
  color: white;
}

.preview {
  background-color: color-success;
}

.edition {
  background-color: color-purple;
}

.button {
  color: color-purple;
  background-color: white;
}
