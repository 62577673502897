@value colors: "components/library/style/colors.css";
@value color-gray from colors;

.container {
  position: absolute;
  bottom: -42px;
  left: 32px;
}

.simulation {
  width: 315px;
  background-color: white;
  padding: 16px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  box-sizing: border-box;
  box-shadow: 0 0 15px #dddddd;
}

.title {
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 4px;
  font-size: 1.15rem;
}

.reduction {
  font-size: 0.9rem;
  text-align: right;
  margin: 0 0 8px;
}

.input {
  border: 1px solid white;
  border-radius: 2px;
  padding: 4px 0;
  outline: none;
  font-weight: 500;
  font-size: 1.1rem;
  font-family: Roboto, sans-serif;
  width: 100%;
  box-sizing: border-box;
}

.input:hover,
.input:focus {
  width: 100%;
  border-color: color-gray;
  padding: 4px;
  transition: 0.3s;
  font-weight: 400;
}

.help {
  margin: 0;
  font-size: 0.9rem;
}

.help a {
  font-size: 0.9rem;
  margin: 0 0 0 4px;
}

.arrow {
  position: absolute;
  bottom: 48px;
  left: -64px;
  z-index: 1;
}

.icon {
  margin-right: 0.5rem;
}

@media screen and (max-width: 768px) {
  .container {
    bottom: -48px;
  }
}

@media screen and (max-width: 560px) {
  .container {
    display: none;
  }
}
