@value colors: "../../../../components/library/style/colors.css";
@value color-purple-bg from colors;

.header {
  background-color: color-purple-bg;
  padding: 2rem; /* ie11 fallback: clamp not supported */
  padding: 2rem clamp(3rem, 10%, calc((100% - 1440px) / 2)) 4rem;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 50%;
}

.logo {
  height: 2rem;
}

.logoName {
  font-family: Fjalla one, sans-serif;
  font-size: 1.5em;
}

.cover {
  width: 80%;
  max-height: 450px;
  object-fit: cover;
  border-radius: 35px 0;
  align-self: flex-end;
  pointer-events: none;
}

.group {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.edit {
  margin-bottom: 16px;
}

.edit .icon {
  margin-right: 4px;
}

.button-group {
  display: none;
}

@media screen and (max-width: 768px) {
  .header {
    padding: 1.5rem clamp(1.5rem, 10%, calc((100% - 1440px) / 2)) 4rem;
  }

  .row {
    flex-wrap: wrap;
  }

  .column {
    width: 100%;
  }

  .cover {
    width: 100%;
  }
}

.whatsapp {
  background-color: #25D366;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.whatsapp a {
  height: 48px;
}

.help {
  margin-top: 0.25rem;
  margin-bottom: 0;
}

.link {
  margin-left: 0.25rem;
}

@media screen and (max-width: 560px) {
  .button-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    row-gap: 0.5rem;
  }

  .button-group .icon {
    margin-right: 0.5rem;
  }
}
