@value colors: "components/library/style/colors.css";
@value color-text-subtle, color-purple, color-success-light, color-success-dark, color-red-bg, color-red-dark from colors;

.poll {
  position: fixed;
  bottom: 0;
  left: 2rem;
  z-index: 50;
  background-color: white;
  padding: 2rem;
  box-shadow: 0 1px 25px #00000040;
  border-radius: 5px;
  width: 320px;
}

.position {
  margin-right: 0;
  margin-left: auto;
  background-color: white;
  color: color-text-subtle;
  transition: 0.3s;
  position: absolute;
  right: 1rem;
  top: -1rem;
}

.position:hover {
  background-color: white;
  color: color-purple;
}

.title {
  font-weight: 500;
  margin: 0;
}

.status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.status .title {
  margin-bottom: 4px;
  text-align: center;
}

.status .description {
  margin: 0;
  color: color-text-subtle;
  text-align: center;
}

.icon-container {
  width: 48px;
  height: 48px;
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.success .icon-container {
  background-color: color-success-light;
  color: color-success-dark;
}

.error .icon-container {
  background-color: color-red-bg;
  color: color-red-dark;
}

.retry {
  margin-top: 1rem;
}

.callback {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.callback p { margin: 0; }

.callback .title {
  font-weight: 500;
  font-size: 1.25rem;
}

.callback .description {
  color: color-text-subtle;
  font-size: 1rem;
}

.callback .phone {
  color: color-purple;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .poll {
    display: none;
  }
}
