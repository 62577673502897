.input {
  border: none;
  background: none;
  outline: none;
  height: 100%;
  width: 100%;
  font-size: 14px;
  font-family: Roboto, sans-serif;
}

.disabled {
  background-color: #f3f3f3;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  min-height: 32px;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}

.container:focus-within {
  border-color: #526af2;
  color: #526af2;
}
