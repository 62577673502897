@value colors: "../../../../../components/library/style/colors.css";
@value color-purple-dark from colors;

.flag {
  height: 13px;
  width: 20px;
  object-fit: cover;
  margin-right: 4px;
}

.lang {
  transition: 0.3s;
}

.lang:hover {
  color: color-purple-dark;
}
