@value colors: "../library/style/colors.css";
@value color-text-subtle, color-error, color-red-bg from colors;

.title {
    margin: 0;
    font-size: 1.5rem;
    max-width: 600px;
}

.description {
    margin-top: 0.5rem;
    color: color-text-subtle;
    max-width: 600px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.submitting {
    margin-left: 0.5rem;
}

.error {
    display: flex;
    align-items: center;
    margin-top: 0.25rem;
    font-size: 0.85rem;
    color: color-error;
    margin-bottom: 0;
}

.icon {
    font-size: 1rem !important;
    margin-right: 0.25rem;
}

.server-error {
    background-color: color-red-bg;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 5px;
    box-sizing: border-box;
}

.server-error .title{
    margin: 0;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: color-error;
}

.server-error .description {
    margin-top: 0.25rem;
    color: black;
}

.button-group {
    display: flex;
    flex-direction: column;
    width: 100%;
}
