.form {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
}

.checkbox {
  margin-right: 0.5rem;
}
