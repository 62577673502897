.layout {
  padding: 2rem; /* fallback for ie11 compatibility */
  padding: 0 clamp(3rem, 10%, calc((100% - 1440px) / 2)) 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 48px;
  box-sizing: border-box;
  margin-top: 1rem;
}

.main {
  width: 60%;
}

.simulation {
  position: relative;
  padding-bottom: 2rem;
}

.button {
  position: sticky;
  top: 4.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0;
  margin-left: auto;
}

@media screen and (max-width: 1024px) {
  .layout {
    padding: 0 clamp(1rem, 10%, calc((100% - 1440px) / 2)) 0;
    flex-direction: column;
  }

  .main {
    width: 100%;
  }
}
