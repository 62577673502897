@value colors: "../../../../../components/library/style/colors.css";
@value color-gray, color-purple, color-purple-dark from colors;

.column {
  position: sticky;
  top: 5rem;
}

.simulation {
  padding: 24px;
  box-shadow: 0 1px 4px #dddddd;
  border-radius: 5px;
  margin-bottom: 16px;
}

.title {
  font-weight: 500;
  display: flex;
  align-items: center;
  margin: 0 0 24px;
}

.icon {
  margin-right: 8px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
}

.select,
.input {
  font-family: Roboto, sans-serif;
}

.input {
  padding: 8px;
  min-height: 32px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid color-gray;
  outline: none;
  transition: 0.3s;
}

.input:hover,
.input:focus {
  border-color: color-purple;
}

.grid {
  max-width: 320px;
  display: flex;
  display: -ms-flexbox;
  flex-direction: row;
  flex-wrap: wrap;
}

.grid > p {
  width: 50%;
  margin-bottom: 1rem;
}

@supports (display: grid) {
  .grid {
    max-width: unset;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    margin-top: 16px;
    row-gap: 16px;
  }

  .grid p {
    margin: 0;
  }
}

.list {
  display: flex;
  flex-direction: column;
}

.gridTitle {
  font-weight: 500;
}

.calendar {
  width: 100%;
  margin-top: 8px;
}

.reduction {
  margin-top: 0;
  text-align: right;
}

.line {
  white-space: pre-line;
}

.select {
  border-color: color-gray;
  padding: 4px;
  border-radius: 5px;
  outline: none;
  transition: 0.3s;
}

.select:hover,
.select:focus {
  border-color: color-purple;
}

.document {
  background: #fff;
  box-shadow: 0 0 15px #dddddd;
  border-radius: 5px;
  padding: 1rem;
  margin-top: 1rem;
}

.lock {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  font-weight: 500;
  margin: 0 0 1rem;
}

.link {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  align-items: center;
  color: color-purple;
  padding: 0;
  margin-bottom: 1rem;
  transition: 0.3s;
}

.link:hover {
  color: color-purple-dark;
}

.more {
  width: 100%;
}
