.loading {
  position: fixed;
  z-index: 1000;
  top: 1rem;
  left: 42%;
  background-color: white;
  padding: 1rem;
  border-radius: 16px;
  box-shadow: 0 4px 25px #dddddd;
  display: flex;
  align-items: center;
  column-gap: 8px;
}
