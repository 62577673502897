.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-weight: 500;
  margin-bottom: 8px;
}

.description {
  margin: 0;
}

.details {
  margin-top: 16px;
}
