.background {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  margin-top: 48px;
  background-color: white;
  border-radius: 5px;
  padding: 24px;
  max-width: 550px;
  width: 90%;
  height: max-content;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.illustration {
  background-color: #faeded;
  width: 65px;
  height: 65px;
  border-radius: 65px;
  margin: auto auto 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  color: #e30101;
  font-size: 30px !important;
}

.title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
}

.description {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.005em;
  color: rgb(0 0 0 / 55%);
  margin-top: 0;
}

.button {
  width: 100%;
  margin-bottom: 0;
}

.disable-scroll {
  height: 100%;
  overflow: hidden;
}

.button-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.button-group button {
  width: 100%;
}
