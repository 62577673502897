@value colors: "components/library/style/colors.css";
@value color-text-subtle, color-purple from colors;

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  max-width: 400px;
}

.content p { margin: 0; }

.content button { width: 100%; }

.title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
}

.description {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-size: 1rem;
  color: color-text-subtle;
}

.ghost {
  min-height: unset;
}

.phone {
  font-weight: 500;
  color: color-purple;
}
