@value colors: "../../../../../components/library/style/colors.css";
@value color-purple, color-purple-dark, color-error from colors;

.list {
  display: grid;
  grid-template-columns: repeat(3, minmax(11rem, 1fr));
  grid-gap: 16px;
  list-style: none;
  padding-left: 0;
}

.document {
  padding: 24px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 15px #dddddd;
}

.title {
  font-weight: 500;
  margin-top: 0;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  column-gap: 4px;
  text-decoration: none;
  background-color: color-purple;
  color: white;
  padding: 8px 16px;
  min-height: 42px;
  border-radius: 5px;
  box-sizing: border-box;
}

.link:hover,
.link:focus {
  color: white;
  background-color: color-purple-dark;
}

.delete {
  color: color-error;
  margin-top: 16px;
}

.hide {
  display: none;
}

@media screen and (max-width: 768px) {
  .list {
    grid-template-columns: repeat(1, 1fr);
  }
}
