@value colors: "components/library/style/colors.css";
@value color-purple, color-gray from colors;

.title {
  font-family: Raleway, sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: color-purple;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid color-gray;
}

.grid p {
  padding: 0.5rem;
  margin: 0;
}

.grid p:first-child {
  border-right: 1px solid color-gray;
}

.grid:last-child {
  border-bottom: 0;
}

.grid-container {
  border: 1px solid color-gray;
}

.grid-title {
  font-weight: 500;
}

.section {
  padding-bottom: 2rem;
}

.funds {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
