.title {
  margin-bottom: 0;
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 16px;
}

.button {
  width: max-content;
}
